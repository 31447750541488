// Enable and Disable the return date inputs
function check_checkbox(target) {
  var w_r = $("#with_return");
  if (target.is(":checked")) {
    w_r.show();
    w_r.find("input").prop("disabled", false);
  } else {
    w_r.hide();
    w_r.find("input").prop("disabled", true);
  }
}
// add asterisk on label for every required field
$("[required]").each(function() {
  $("label[for=" + $(this).attr("id") + "]").append(" <span>*</span>");
});
// set minimum return date the same date as departure_date
var departure_date = $("#departure_date");
$("#return_date").attr("min", departure_date.val());
departure_date.on("change", function() {
  $("#return_date").attr("min", departure_date.val());
});
// With return checkbox
var form_return_checkbox = $("#departure_with_return");
check_checkbox(form_return_checkbox);
form_return_checkbox.on("change", function() {
  check_checkbox($(this));
});
$("form").submit(function(e) {
  e.preventDefault();
  var formData = new FormData(this);
  var data = {};
  for (var [key, value] of formData.entries()) {
    data[key] = value;
  }
  var btn = $("form .btn");
  btn.prop("disabled", true);
  $.ajax({
    url: "/bk",
    type: "POST",
    data: JSON.stringify(data),
    contentType: "application/json; charset=utf-8",
    success: function() {
      var msg = $("#msg");
      msg.html("");
      msg.addClass("alert alert-success alert-dismissible");
      msg.text("Message Has been sent");
    },
    error: function(e) {
      var msg = $("#msg");
      msg.html("");
      msg.addClass("alert alert-warning alert-dismissible");
      msg.text(e.responseText);
      console.log(e);
      btn.prop("disabled", false);
    }
  });
});
// fixes scroll if url page loads with hash
var hash = window.location.hash;
var init = 1;
if (hash) {
  if (window.location.pathname.indexOf("fleet") !== -1) {
    setTimeout(function() {
      $("[data-filter='" + hash.replace("#", ".") + "']").click();
    }, 500);
  } else {
    window.addEventListener("scroll", function() {
      // select fleet tab from hash url
      if (init) {
        var target = document.querySelector(hash).offsetTop;
        var nav_height = document.querySelector(".navbar-fixed-top").clientHeight;
        setTimeout(function() {
          window.scrollTo(0, target - (nav_height + 15));
        }, 500);
      }
      init = 0;
    });
  }
}
function changefleetbg() {
  let target = $(".work-filter .filter.active");
  let custom_bg = $(".custom_bg");
  if (target.attr("data-bg") !== custom_bg.attr("data-bg")) {
    custom_bg.attr("style", `background-image: url("/img/fleet/${target.attr("data-bg")}.jpg")`);
  }
  if (!target.attr("data-bg")) {
    custom_bg.attr("style", "background-image: url(\"/img/fleet/bg.jpg\")");
  }
}
if ($("#fleet")[0]) {
  $(".work-filter").on("click", changefleetbg);
}
//c_c creates cookie to remeber that the user accepted the cookies
function c_c() {
  let d = new Date();
  //set for 30 days
  d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = "acepted_cookies=true;path=/;expires=" + d.toUTCString() + ";";
}
let cookie = document.createElement("div");
cookie.setAttribute("id", "aggreement_cookie");
cookie.innerHTML = "We use essential cookies to ensure the basic functionality of our website, as per our <a href=\"/pdf/Cookies%20Policy.pdf\" target=\"_blank\"><strong>Cookies Policy.</strong></a><button>Agree</button>";
cookie.style.cssText = "position:fixed;bottom:15px;left:50%;transform:translateX(-50%);max-width:400px;padding:15px;background-color: #fff;box-shadow: 0 0 1px #333;z-index:100;";
cookie.querySelector("button").style.cssText = "display:block;margin-top:15px;width:100%;border-radius:0;border:1px solid #333;box-shadow:0 0 1px #333;background:#ddd;";
//checks if he had accepted cookies and shows warning
//else updates cookie
function cookieInit() {
  if (document.cookie.indexOf("acepted_cookies") == -1) {
    document.body.appendChild(cookie);
    cookie.querySelector("button").addEventListener("click", function() {
      c_c();
      cookie.parentNode.removeChild(cookie);
    });
  } else {
    //if he has accepted update cookie time
    c_c();
  }
}
//When page fully loads run script
window.onload = function() {
  cookieInit();
};
